








































import { Client, Practitioner, Site } from '@/models';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { useRouter } from '@/router';

export default defineComponent({
  name: 'ClientPractitionersPage',
  setup() {
    const { route } = useRouter();

    const client = ref<Client | null>(null);
    const sites = ref<Site[]>([]);
    const practitioners = ref<Practitioner[]>([]);
    const loading = ref(false);

    async function fetchClient(): Promise<void> {
      loading.value = true;
      client.value = await Client.find(route.value.params.uuid);
      sites.value = await client.value
        .sites()
        .append('primaryPractitioner')
        .with('practitioners', 'practitioners.primarySites')
        .get();
      // sites.value.forEach(async (site: Site) => await site.loadPractitioners());
      await fetchPractitioners();
      loading.value = false;
    }

    const fetchPractitioners = async () => {
      practitioners.value = await Practitioner.with('primarySites').getAll();
    };

    onMounted(() => fetchClient());

    async function setPractitioners(practitioners: Practitioner[], site: Site): Promise<void> {
      await site.updateRaw({
        practitioners: practitioners.map((practitioner) => practitioner.uuid),
      });
    }

    const setPrimaryPractitioner = async (item: Practitioner, site: Site) => {
      await site.directUpdate({
        primaryPractitioner: item.uuid,
      });

      await fetchPractitioners();
    };

    return {
      client,
      sites,
      practitioners,
      setPractitioners,
      loading,
      setPrimaryPractitioner,
    };
  },
});
